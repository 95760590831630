<template>
	<!-- 总部的市场部 -->
	<div class="total">
		<div class="flex justify-content-between margin-tb margin-lr-lg">
			<el-input placeholder="搜索" v-model="UserName" class="input-with-select" style="width: 400px;">
				<el-select v-model="customerSelect" slot="prepend" style="width: 110px">
					<el-option label="客户名称" value="1"></el-option>
				</el-select>
			</el-input>
			<div >
				<el-button class="cursor" size="mini" style="margin-right: 10px;" @click="changeGrouprecord"
					v-if="listQuery.CustomerType==4">
					客户分组记录</el-button>
				<el-button type="primary" class="cursor" @click="CreatClient" size="mini"
					v-if="listQuery.CustomerType==4">
					创建客户</el-button>

	<el-button type="primary" class="cursor" @click="showimport=true" size="mini"
					v-if="listQuery.CustomerType==4 ">
					导入客户</el-button>
					
				<groupRecord v-if="grouprecord" @closepop="closeGroupRecord"></groupRecord>
			</div>
		</div>
		
		<div class="margin-lg"  >
			<as-list :ajaxMethods="{ list: 'customerSelectMyCustomer' }" :listQueryProp="listQuery">
				<el-table-column label="客户全名" prop="UserName" width="140" height="40">
					<template slot-scope="scope">
						<el-link type="primary" @click="editHandle(scope.row.id)">
							{{ scope.row.UserName }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.AddTime | dateTimeFilter}}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-dropdown @command="handleCommand">
							<span class="el-dropdown-link cursor">
								操作<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{ids:scope.row.id}">放弃客户</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</as-list>
		</div>
		<customerDetails :Visible="editVisible" :customersID="ids" @closepop="closeEditPop"></customerDetails>

		<importCustom
		:Visible="showimport"
		:title="'客户导入'" 
		:action="'/antis-service/api/Customer/Import?type=4&isadd=true'"
		@closepop="importclose"
	  ></importCustom>
  
  
	</div>
</template>
<script>
	import customerDetails from './components/customerDetails.vue'
	import AsList from "@/components/as-list";
	import groupRecord from './components/groupRecord.vue'
	import importCustom from '../myClient/components/importCustom.vue'
	export default {
		components: {
			AsList,
			customerDetails,
			groupRecord,
			importCustom
		},
		data() {
			return { 
				showimport:false,
				multipleSelection: [],
				CustomerList: [],
				editVisible: false,
				UserName: '',
				customerSelect: "1",
				ids:0,
				grouprecord:false,
				listQuery: {
					KeyWord:'',
					CustomerType: 4,
					pageIndex: 1,
					pageSize: 10,
					Sort: [{
						Field: "id",
						Type: 1
					}]
				},
			};
		},
		watch:{
			UserName(val){
				this.listQuery.KeyWord = val
			}
		},
		created() {
			
		},
		methods: {
			importclose(){
				this.listQuery= JSON.parse(JSON.stringify(this.listQuery));
				this.showimport= false;
			},
			CreatClient() {
				this.$router.push('/HMD/createClient')
			},
			editHandle( ids) {
				this.editVisible = true;
				this.ids = ids
			},
			closeEditPop() {
				this.editVisible=false
				this.CustomerList.pop(this.CustomerList.push())
			},
			changeGrouprecord() {
				this.grouprecord = true
			},
			closeGroupRecord() {
				this.grouprecord = false
			},
			handleCommand(command) {
				this.$confirm('确定放弃您的该客户?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let list = {}
					list.CustomerUserId = command.ids
					this.API.customerGiveUpCustomer(list).then(res => {
						if (res.code == -1) {
							this.$message.eval(res.message)
						} else if (res.code == 200) {
							this.$message.success("放弃成功")
							this.listQuery = {
								CustomerType: 4,
								pageIndex: 1,
								pageSize: 15,
								Sort: [{
									Field: "id",
									Type: 1
								}]
							}
						}
					})
				}).catch(() => {
			
				});
			}
		},
	};
</script>

<style scoped>
	>>>el-table-column {
		height: 40px;
	}

	.color-box {
		color: #0081ff;
		border: 1px solid #8cc5ff;
		background-color: #e8f5ff;
	}

	.work-title-content div {
		padding: 0 5px 0 0px;
	}

	.total {
		width: 100%;
	}

	.shool {
		margin: 0px 10px;
	}

	.configure {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	>>>.el-select .el-input {
		width: 110px !important;
	}

	.configure {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.el-select .el-input {
		width: 105px;
	}
</style>
